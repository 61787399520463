// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export const environment = {
  production: false,
  apiUrl: "http://localhost:4200/",
};

export const baseApiUrl = 'https://api-dashboard.nstecom.xyz';
export const apiUrl = `${baseApiUrl}/api.php`;
export const reportApiUrl = `${baseApiUrl}/report.php`;
export const apiUrlTest = `${baseApiUrl}/api_test.php`;
export const stripeApiUrl = `${baseApiUrl}/dispute_api.php`;
export const printifyApiUrl = `${baseApiUrl}/printify_api.php`;
export const burgerPrintApiUrl = `${baseApiUrl}/burgerPrint_api.php`;
export const burgerPrintGetOrdersUrl = `${baseApiUrl}/burgerPrint_getOrders.php`;
export const merchizeApiUrl = `${baseApiUrl}/merchize_api.php`;
export const emailTemplatesUrl = `${baseApiUrl}/emailTemplates_api.php`;
export const getPrintifyOrders = `${baseApiUrl}/printify_get_all_orders_txt.php?store_id=`
export const stripeApi = 'https://api.stripe.com/v1/';
export const assigneeList = ['Loan', 'An', 'Huong', 'Quang', 'Toàn', 'Long', 'None'];
export const assigneeListDesigner = ['Lệ', 'None'];
export const statusList = ['processing', 'fulfilled', 'pending', 'completed', 'cancelled', 'refunded', 'item-unavailable'];
export const artworkStatusList = ['Open', 'In-Progress', 'Pending', 'Done'];
export const readHtmlApiUrl = 'https://api-dashboard.nstecom.xyz/readhtml.php?url=';
export const shippingClass = ['Economical', 'Standard', 'Priority', 'FedEx Express', 'Free Shipping'];
export const syncTrackingStatus = ['Missing', 'Wait for syncing'];
// export const syncTrackingStatus = {
//    missing: 'Missing',
//    waitForSyncing: 'Wait for syncing',
//    synced: 'Synced'
// };
export const orderJsonApiPath = 'order-json-v2.php';
export const teleBotId = '5915559093:AAENcg8iKnWeeJBb_bPNM8O9Ky_K0RVplyA';
export const teleGroupIds = {
  group3ae: '-819388798',
  foreverAlone: '-637412418',
  storeSupport: '-445026335'
}
export const trello = {
  baseApiUrl: 'https://api.trello.com/1/',
  apiKey: `090b93fa0f5104f52fabe2bfdcba9e05`,
  apiSecret: '4d2d0cc2dc14f5f0b4c40fef4a98fb5a119751764404ccae3ec9d0fc1c86b6ef',
  token: 'ATTA1d594193046e9a1af5f398da41feda934614ad28e544b0a12cfa18104c1db2a7F988439A',
  columnId: {
    todo: '674fdc378035c758f7b9542e',
    inprogress: '674fdc3fd0ca86e4c637cdc1',
    pending: '674fdc52e4bae8711fca1280',
    done: '674fdc5713b1b21d865ab524'
  }
}
export const url17trackBase = 'https://t.17track.net/en#nums=';
export const carrierId17track = {
  'FJEX': '190353',
  'LTEXP': '190172',
  'ChinaPost': '3011'
}
export const fulfillmentSupplier = {
  printify: 'printify',
  burgerprint: 'burgerprint',
  amazee: 'amazee',
  merchize: 'merchize',
  infinity: 'infinity',
  zack: 'zack',
  chopper: 'chopper',
  hoanpham: 'hoanpham',
  cbs: 'cbs',
  jenny: 'jenny'
}
export const jsonbin = {
  masterKey: '$2a$10$ljehOWQh.41nTWy20Lh7I.r3N5Z5HrLjYtRsaIUWofQSyY5dHO41S'
}
export const paypalApiUrl = {
  getToken: `https://api-m.paypal.com/v1/oauth2/token`,
  listDisputes: `https://api-m.paypal.com/v1/customer/disputes/`
}
export const woocommerceStores = [
  {
    storeName: 'Zivarts',
    url: 'https://zivarts.com/',
    consumerKey: 'ck_a48496a58a94857f86c91f78b3c6dfa540993244',
    consumerSecret: 'cs_b806557490d91cc2a623f6d7bce43746ba752370',
    assignee: assigneeList[1],
    assigneeDesigner: assigneeListDesigner[1]
  }
];

// export const ggSheetAPI = 'AIzaSyCJfPuyTzQ30C8hsH6Frd7Z2xPuXbEy6F0';
export const ggSheetAPI = 'AIzaSyAU6TDRAS4N9mV0S58Ud-0BnfWmzDgInKk';

export const stripes = [
  {
    name: 'test',
    sk: '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'
  }
]

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyC3yqOnl1sigj0tTrlm6SvnQlVKqu-zuEc",
  authDomain: "mmobase-f4ecc.firebaseapp.com",
  projectId: "mmobase-f4ecc",
  storageBucket: "mmobase-f4ecc.appspot.com",
  messagingSenderId: "792111866277",
  appId: "1:792111866277:web:27ccecfa66cea6ae62c84c",
  measurementId: "G-2RYZ0MVQDW"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

export const prinitifyVariantFull = {
  'External ID': '',
  'Label': 'CSV',
  'Shipping method': 1,
  'First name': '',
  'Last name': '',
  'Email': '',
  'Phone': '',
  'Country': '',
  'Region': '',
  'Address line 1': '',
  'Address line 2': '',
  'City': '',
  'Zip': '',
  'Quantity': 1,
  'Print Provider ID': 3,
  'Blueprint ID': 5,
  'Variant ID': 17646,
  'Print area back': '',
  'Print area front': 'https://www.dropbox.com/s/rikt9tpe7s0aruf/Funny%20alien%20Abduction%20Get%20In%20Loser%20Were%20Doing%20Butt%20Stuff%20Aliens%20UFO%20T-Shirt.png?raw=1',
  'Print area neck': '',
  'Print area left sleeve': '',
  'Print area right sleeve': '',
  'Print area neck outer': '',
};

export const printifyVariantEdit = {
  'External ID': '',
  'First name': '',
  'Last name': '',
  'Email': '',
  'Phone': '',
  'Country': '',
  'Region': '',
  'Address line 1': '',
  'City': '',
  'Zip': '',
};

export const burgerPrintVariantFull = {
  'Name': '',
  'Email': '',
  'Financial Status': '',
  'Paid at': '',
  'Created at': '',
  'Lineitem quantity': '',
  'Lineitem name': '',
  'Lineitem sku': '',
  'Shipping Name': '',
  'Shipping Street': '',
  'Shipping Address1': '',
  'Shipping Address2': '',
  'Shipping Company': '',
  'Shipping City': '',
  'Shipping Zip': '',
  'Shipping Province': '',
  'Shipping Country': '',
  'Shipping Phone': '',
  'Notes': '',
  'Id': '',
  'Design front url': '',
  'Design back url': '',
  'Mockup front url': '',
  'Mockup back url': '',
  'Check vaild adress': 0,
  'Unit Amount': '',
  'Currency': '',
  'Shipping method': '',
  'IOSS Number': '',
  'Store name': ''
};

export const burgerPrintVariantEdit = {
  'Name': '', // Store Order ID
  'Email': '',
  'Lineitem quantity': '',
  'Shipping Name': '',
  'Shipping Address1': '',
  'Shipping Address2': '',
  'Shipping Phone': '',
  'Shipping City': '',
  'Shipping Zip': '',
  'Shipping Province': '',
  'Shipping Country': '',
};

export const amazeeVariantFull = {
  'NOTE': '',
  'FF date': '',
  'Basecost': '',
  'Carrier': '',
  'Tracking Number': '',
  'Date': '',
  'Order Number': '',
  'Shipping Name': '',
  'Address': '',
  'City': '',
  'Post Code': '',
  'State': '',
  'Country': '',
  'Phone': '',
  'Email': '',
  'Qty': '',
  '-': '',
  'Image URL': '',
  'Lineitem Name - Color-Size': '',
  'Type-Size': '',
  'Custom Name - Number': '',
  'Customer Note': ''
};
export const amazeeVariantEdit = {
  'FF date': '',
  'Order Number': '',
  'Shipping Name': '',
  'Address': '',
  'City': '',
  'Post Code': '',
  'State': '',
  'Country': '',
  'Phone': '',
  'Email': '',
  'Qty': '',
  'Image URL': '',
  'Lineitem Name - Color-Size': '',
  'Type-Size': '',
  'Custom Name - Number': '',
  'Customer Note': ''
};

export const nstVariantFull = {
  'Date': '',
  'Order ID': '',
  'Package Image': '',
  'NAME': '',
  'SHIPPING ADDRESS': '',
  'CITY': '',
  'POST CODE': '',
  'STATE': '',
  'COUNTRY': '',
  'PHONE': '',
  'EMAIL': '',
  'QUANTITY': '',
  'LINK': '',
  'CUSTOM NAME': '',
  'CUSTOM NUMBER': '',
  'PRODUCT NAME': '',
  'Size': '',
  'CUSTOMER NOTE': ''
};
export const nstVariantEdit = {
  'Date': '',
  'Order ID': '',
  'NAME': '',
  'SHIPPING ADDRESS': '',
  'CITY': '',
  'POST CODE': '',
  'STATE': '',
  'COUNTRY': '',
  'PHONE': '',
  'EMAIL': '',
  'QUANTITY': '',
  'LINK': '',
  'CUSTOM NAME': '',
  'CUSTOM NUMBER': '',
  'PRODUCT NAME': '',
  'Size': '',
  'CUSTOMER NOTE': ''
};

export const hoanphamVariantFull = {
  // 'Date': '',
  // 'Status': '',
  // 'Acc': '',
  // 'Link Label': '',
  'Order ID': '',
  'Shipping Method': '1',
  'First Name': '',
  'last Name': '',
  'Email': '',
  'Phone': '',
  'Country': '',
  'Region': '',
  'Address line 1': '',
  'Address line 2': '',
  'City': '',
  'Zip': '',
  'Quantity': '',
  'Print area front': '',
  'Print area back': '',
  'Mockup Front': '',
  'Mockup Back': ''
};
export const hoanphamVariantEdit = {
  'Date': '',
  'Order ID': '',
  'First Name': '',
  'last Name': '',
  'Email': '',
  'Phone': '',
  'Country': '',
  'Region': '',
  'Address line 1': '',
  'Address line 2': '',
  'City': '',
  'Zip': '',
  'Quantity': '',
  'Title Product': '',
  'Mockup Front': '',
  'Note': '',
};


export const merchizeVariantFull = {
  'mockUpFront': '',
  'mockUpBack': '',
  'designFront': '',
  'designBack': '',
  'designSleeve': '',
  'designHood': '',
  'type': '',
  'sku': '',
  'title': '',
  'size': '',
  'color': '',
  'orderNumber': '',
  'quantity': '',
  'name': '',
  'address1': '',
  'address2': '',
  'city': '',
  'state': '',
  'country': '',
  'phone': '',
  'email': '',
  'postalCode': '',
  'tax': ''
};

export const merchizeVariantEdit = {
  'mockUpFront': '',
  'type': '',
  'title': '',
  'size': '',
  'color': '',
  'orderNumber': '',
  'quantity': '',
  'name': '',
  'address1': '',
  'address2': '',
  'city': '',
  'state': '',
  'country': '',
  'phone': '',
  'email': '',
  'postalCode': '',
};

export const infinityVariantFull = {
  'No.': '',
  'Date': '',
  'Order ID': '',
  'Order Number': '',
  'Item Name': '',
  'Size': '',
  'Color': '',
  'Gender': '',
  'Sku': '',
  'Custom Name': '',
  'Custom number': '',
  'Image': '',
  'Link': '',
  'Quantity': '',
  'Name': '',
  'Address 1&2 (Shipping)': '',
  'City': '',
  'State': '',
  'Postcode': '',
  'Country': '',
  'Phone (Billing)': '',
  'Email': '',
  'Shipping Method Title': '',
  'Status': '',
  'Track Number': '',
  'Track Link': '',
  'Note': '',
  'Refund': '',
  'Refund Date': '',
};

export const infinityVariantEdit = {
  'Date': '',
  'Order ID': '',
  'Order Number': '',
  'Item Name': '',
  'Size': '',
  'Gender': '',
  'Image': '',
  'Quantity': '',
  'Name': '',
  'Address 1&2 (Shipping)': '',
  'City': '',
  'State': '',
  'Postcode': '',
  'Country': '',
  'Phone (Billing)': '',
  'Email': '',
  'Note': '',
};

export const zackVariantFull = {
  'Date FF': '',
  'full_name': '',
  'email': '',
  'phone': '',
  'address1': '',
  'address2': '',
  'city': '',
  'province': '',
  'zip': '',
  'country_code': '',
  'payment_account': '',
  'Paygate': '',
  'transaction_id': '',
  'reference_id': '',
  'reference_id_2': '',
  'description': '',
  'quantity': '',
  'variant_code': 'CLOTHING-F',
  'image url': '',
  'design1': '',
  'design2': '',
  'Lineitem SKU': '',
  'option_ship': '',
  'seller_code': 'F02',
  'Size': '',
  'Custom Name': '',
  'Custom Number': '',
  'Customer Note': '',
};
export const zackVariantEdit = {
  'Date FF': '',
  'full_name': '',
  'email': '',
  'phone': '',
  'address1': '',
  'address2': '',
  'city': '',
  'province': '',
  'zip': '',
  'country_code': '',
  'payment_account': '',
  'Paygate': '',
  'transaction_id': '',
  'reference_id': '',
  'reference_id_2': '',
  'description': '',
  'quantity': '',
  'image url': '',
  'design1': '',
  'design2': '',
  'Lineitem SKU': '',
  'option_ship': '',
  'Size': '',
  'Custom Name': '',
  'Custom Number': '',
  'Customer Note': '',
};

export const jennyVariantFull = {
  'Date': '',
  'Order Number': '',
  'Tracking': '',
  'Carrier': '',
  'IMAGE': '',
  'Item Name': '',
  'Product Variation': '',
  'Quantity': '',
  'PRICE': '',
  'First Name (Shipping)': '',
  'Address 1&2 (Shipping)': '',
  'City (Shipping)': '',
  'Postcode (Shipping)': '',
  'State Name (Shipping)': '',
  'Country Code (Shipping)': '',
  'Country Name (Shipping)': '',
  'Phone (Billing)': '',
  'Email (Billing)': '',
};
export const jennyVariantEdit = {
  'Date': '',
  'Order Number': '',
  'IMAGE': '',
  'Item Name': '',
  'Product Variation': '',
  'Quantity': '',
  'First Name (Shipping)': '',
  'Address 1&2 (Shipping)': '',
  'City (Shipping)': '',
  'Postcode (Shipping)': '',
  'State Name (Shipping)': '',
  'Country Code (Shipping)': '',
  'Country Name (Shipping)': '',
  'Phone (Billing)': '',
  'Email (Billing)': '',
};